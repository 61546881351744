import { createRef, Component } from "react";
import { useSelector } from "react-redux";
import classes from "classnames";
import styles from "./Image.css";
import AssociatedMediaPlaceholder from "./components/AssociatedMediaPlaceholder";
import DefaultPlaceholder from "./components/DefaultPlaceholder";
import LibraryItemPlaceholder from "./components/LibraryItemPlaceholder";
import { selectImagePreferences } from "../../selectors";
import { useSelectI18nStringById } from "../../../util/custom-hooks";

export class Image extends Component {
    static observer;
    static loadImages = (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.onIntersect();
                observer.unobserve(entry.target);
            }
        });
    };

    image = createRef();

    state = {
        loaded: false,
        show: false,
    };

    attachObserver = () => {
        this.image.current.onIntersect = this.onIntersect;

        Image.observer =
            Image.observer ||
            new IntersectionObserver(Image.loadImages, {
                rootMargin: "100% 0px 100% 0px",
                threshold: 0.1,
            });

        Image.observer.observe(this.image.current);

        this.image.current.dispatchEvent(new Event("load"));
    };

    reattachObserver = () => {
        if (Image.observer) {
            Image.observer.unobserve(this.image.current);
        }

        this.attachObserver();
    };

    handleShow = () => {
        this.setState({ show: true }, () => {
            this.reattachObserver();
        });
    };

    componentDidMount() {
        if (this.image.current) {
            this.attachObserver();
        }
    }

    componentDidUpdate(prevProps) {
        const { disableImages } = this.props;

        if (prevProps.disableImages !== disableImages) {
            disableImages
                ? this.setState({ show: false, loaded: false })
                : this.reattachObserver();
        }
    }

    componentWillUnmount() {
        if (this.image.current) {
            Image.observer.unobserve(this.image.current);
        }
    }

    onIntersect = () => {
        if (!this.props.disableImages) {
            this.setState({ show: true });
        }
    };

    onLoad = () => {
        this.setState({ loaded: true });
    };

    render() {
        let {
            alt,
            className,
            disableImages,
            inline,
            isAssociatedMedia,
            isLibraryItem,
            isPDF,
            placeholderClass,
            passedStyle,
            src = "",
            width = 16,
            height = 9,
            selectI18nStringById,
            srcset = this.props.srcSet,
            ...props
        } = this.props;

        const { loaded, show } = this.state;
        const Wrapper = inline ? "span" : "div";
        const placeHolderText = isPDF ? "PDFText" : "imageText";

        return (
            <Wrapper
                ref={this.image}
                className={classes(
                    styles.imageWrapper,
                    show && !loaded && placeholderClass
                )}
                style={passedStyle}
            >
                {show && (
                    <img
                        {...props}
                        alt={alt || ""}
                        className={classes(className, styles.img)}
                        height={height}
                        onLoad={this.onLoad}
                        src={src}
                        srcSet={srcset}
                        width={width}
                    />
                )}
                {!loaded &&
                    (isAssociatedMedia ? (
                        <AssociatedMediaPlaceholder
                            alt={alt}
                            className={classes(
                                className,
                                show && styles.loading
                            )}
                            disableImages={disableImages}
                            handleShow={this.handleShow}
                            height={height}
                            imageText={selectI18nStringById(placeHolderText)}
                            inline={inline}
                            width={width}
                            {...props}
                        />
                    ) : isLibraryItem ? (
                        <LibraryItemPlaceholder
                            disableImages={disableImages}
                            inline={inline}
                        />
                    ) : (
                        <DefaultPlaceholder
                            alt={alt}
                            className={classes(
                                className,
                                show && styles.loading
                            )}
                            disableImages={disableImages}
                            handleShow={this.handleShow}
                            height={height}
                            imageText={selectI18nStringById(placeHolderText)}
                            inline={inline}
                            isLibraryItem={isLibraryItem}
                            width={width}
                            {...props}
                        />
                    ))}
            </Wrapper>
        );
    }
}

const ImageContainer = (props) => {
    const disableImages = useSelector(selectImagePreferences);
    const selectI18nStringById = useSelectI18nStringById();

    return (
        <Image
            selectI18nStringById={selectI18nStringById}
            disableImages={disableImages}
            {...props}
        />
    );
};

ImageContainer.displayName = ImageContainer;

export default ImageContainer;
